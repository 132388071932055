import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { createDynamicURL } from '../../utils/dynamicParams';
// ----------------------------------------------------------------------
const initialState = {
    status: 'idle',
    locations: [],
    location: null,
};
// ----------------------------------------------------------------------
export const getLocations = createAsyncThunk('location/getLocations', async ({ countryId = '', agencyIds = '', branchId = '', isPaging = false }) => {
    let data;
    const queryParams = {
        search: {
            'state.countryId': countryId,
            'branchLocation.branch.agencyId': agencyIds,
            'branchLocation.branchId': branchId,
        },
        isPaging,
    };
    const url = createDynamicURL('/locations', queryParams);
    try {
        const response = await axios.get(url);
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
// ----------------------------------------------------------------------
const slice = createSlice({
    name: 'location',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getLocations.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getLocations.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.locations = action.payload.data;
        })
            .addCase(getLocations.rejected, (state, action) => {
            state.status = 'failed';
        });
    },
});
// Reducer
export const reducer = slice.reducer;
export default slice.reducer;
